<template>
  <div class="sourceControl">
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick">
      <el-tab-pane
        :label="$t('lang.manualChannel')"
        name="manual"></el-tab-pane>
      <el-tab-pane
        :label="$t('lang.channelPool')"
        name="pool"></el-tab-pane>
      <el-tab-pane
        :label="$t('lang.channelSetting')"
        name="settingChannel"></el-tab-pane>
      <router-view></router-view>
    </el-tabs>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      activeName: 'manual'
    }
  },
  mounted () {
    let tabKey = this.$route.path
    this.activeName = tabKey.includes('/') && tabKey.split('/').length > 2 ? tabKey.split('/')[2] : 'manual'
  },
  methods: {
    ...mapMutations('common', [
      'CHANGE_SOURCECONTROL_TAB'
    ]),
    // tab切换
    handleClick (tab, event) {
      this.$router.push({ name: tab.name })
      this.activeName = tab.name
      this.CHANGE_SOURCECONTROL_TAB(tab.name)
    }
  }
}
</script>
<style scoped>
.sourceControl {
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  padding-top: 36px;
  position: relative;
}
</style>
